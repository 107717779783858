import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Slick1 from "../img/slick4.png";
import Slick2 from "../img/slick5.png";
import Slick3 from "../img/slick6.png";
import { useState } from "react";
import "../scss/hwch.scss";
import "../scss/curusel.scss"
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

export function CarouselBox() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const { t } = useTranslation();
  const modail = t("projects_slider", { returnObjects: true });

  return (
    <Carousel className="b_g_c" activeIndex={index} onSelect={handleSelect}>
      {/*flex-container 1*/}
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className="carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title")}</h2>
              <p className="main_txt">{t("projects_slider.info")}</p>
          </div>
          <div className="carusel-cast-style_img">
              <img className="slick_img" src={Slick3} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className=" carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title1")}</h2>
              <p className="main_txt">{t("projects_slider.info1")}</p>
          </div>
          <div className="carusel-cast-style_img">
            <img className="slick_img" src={Slick1} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
        <Carousel.Item>
            <div className="carusel-cast-style">
                <div className="carusel-cast-style_item">
                  <h2 className="h2-castem">{t("projects_slider.title2")}</h2>
                  <p className="main_txt">{t("projects_slider.info2")}</p>
                </div>
                <div className="carusel-cast-style_img">
                  <img className="slick_img" src={Slick2} alt="Second slide" />
                </div>
            </div>
        </Carousel.Item>
    </Carousel>
  );
}
