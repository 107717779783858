import React, { useEffect, useState } from "react";
import "../scss/cost.scss";
import { BtnCostInternational } from "./button";
import logoApple from "../img/modail/logos_apple-pay.svg";
import logoGoogle from "../img/modail/logos_google-pay.svg";
import logoVisa from "../img/modail/logos_visa.svg";
import logoMaster from "../img/modail/logos_master.svg";
import { useTranslation } from "react-i18next";

export function CostInternational() {
  const [act, stateAct] = useState(false);
  const [id, stateId] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const location = document.location.search;
    const idPoster = location.split("=")[1];

    if (idPoster === "1") {
      stateId(false);
    } else if (idPoster === "2") {
      stateId(true);
    } else {
      stateId(false);
    }
  }, []);

  return (
    <div
      className={act ? "cost-international cost__act" : "cost-international"}
    >
      <div className="cost-international_wrapper">
        <span className="cost_title">{t("home-content_sub-title")}</span>
      </div>

      <div
        className={
          act
            ? "cost-international_button cost_button__act"
            : "cost-international_button"
        }
      >
        <div className="box-wrapper_form ">
          <BtnCostInternational
            id="5"
            href={id ? "" : "https://donate.stripe.com/fZecOW2nqeQT9RS5lo"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="10"
            href={id ? "" : "https://donate.stripe.com/00g7uC3ru8svggg3di"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="25"
            href={id ? "" : "https://donate.stripe.com/8wM9CK1jmeQT6FG3df"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="50"
            href={id ? "" : "https://donate.stripe.com/7sI9CK1jm7or4xy8xE"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="100"
            href={id ? "" : "https://donate.stripe.com/fZe3em4vygZ10hi3dj"}
          ></BtnCostInternational>
          {/* <BtnCostInternational
            id="200"
            href={id ? "" : "https://buy.stripe.com/7sI16ebY08svaVW5kM"}
          ></BtnCostInternational> */}
          <BtnCostInternational
            id="500"
            href={id ? "" : "https://donate.stripe.com/3cscOW0fi8sv6FGdRV"}
          ></BtnCostInternational>
        </div>

        <div className="type-of-cards-inter">
          <img className="header-master_logo" src={logoMaster} alt="" />
          <img className="header-visa_logo" src={logoVisa} alt="" />
          <img className="header-google_logo" src={logoGoogle} alt="" />
          <img className="header-apple_logo" src={logoApple} alt="" />
        </div>
      </div>
    </div>
  );
}
