import React, { useEffect, useState } from "react";
import "../scss/cost.scss";
import { ButtonCost } from "./button";
import logoApple from "../img/modail/logos_apple-pay.svg";
import logoGoogle from "../img/modail/logos_google-pay.svg";
import logoVisa from "../img/modail/logos_visa.svg";
import logoMaster from "../img/modail/logos_master.svg";
import { useTranslation } from "react-i18next";

let urlPAy = document.location.search;
let params = new URLSearchParams(urlPAy);
let metaInfo = {
  merchantPointId: "1",
  merchantId: "10",
  posterId: "1",
  desc: "1",
};

for (let pair of params.entries()) {
  if (pair[0]) {
    metaInfo[pair[0]] = pair[1];
  }
}

export function ModailCost() {
  const [act, stateAct] = useState(false);
  const [meta, stateMeta] = useState({
    merchantPointId: "1",
    merchantId: "10",
    posterId: "1",
    desc: "1",
  });
  const [cost, stateCost] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    stateMeta(metaInfo);
    console.log(t("cost"))
    const costButton = Array.prototype.slice.call(
      document.querySelectorAll(".cost-button")
    );
    const displayCurrency = document.querySelector("#imputCost");
    costButton.forEach((el) => {
      el.addEventListener("click", (el) => {
        stateCost(el.target.id);
        document.location.href = `https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${meta["merchantPointId"]}&merchantId=${meta["merchantId"]}&posterId=${meta["posterId"]}&invoice=${el.target.id}&desc=${meta["desc"]}`;
      });
    });

    displayCurrency.addEventListener("keyup", () => {
      stateCost(displayCurrency.value);
    });

    // document.querySelector("#next").addEventListener("click", () => {
    //   stateCost(displayCurrency.value);

    //   if (cost >= 15 && cost <= 100000) {
    //     window.open(`https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${meta["merchantPointId"]}&merchantId=${meta["merchantId"]}&posterId=${meta["posterId"]}&invoice=${cost}&desc=${meta["desc"]}`)
    //     displayCurrency.value = cost;
    //     displayCurrency.classList.remove("error");
    //   } else if (cost <= 14 || cost === 0) {
    //     displayCurrency.value = "";
    //     displayCurrency.placeholder = t("cost");
    //     displayCurrency.classList.add("error");
    //   } else {
    //     displayCurrency.value = "";
    //     displayCurrency.placeholder = "вкажіть суму";
    //     displayCurrency.classList.add("error");
    //   }
    // });
  }, [meta, cost]);

  return (
    <div className={act ? "cost cost__act" : "cost"} id="donate-form">
      <div className="cost_title">
        <span>{t("home-content_sub-title")}</span>
      </div>
      <div
        className={
          act ? "cost_button cost_button__act" : "cost_button cost_button__act"
        }
      >
        <div className="box-wrapper">
          <ButtonCost id="500"></ButtonCost>
          <ButtonCost id="100"></ButtonCost>
          <ButtonCost id="35"></ButtonCost>
        </div>
        <div className="cost-text">{t("donation-title")}</div>
        <input
          className="input-donate"
          type="number"
          id="imputCost"
          placeholder=" ₴100"
        />
        <button className="btn-donate" id="next" onClick={()=>{

          const displayCurrency = document.querySelector('#imputCost');
          if (cost >= 15 && cost <= 100000) {
            stateCost(displayCurrency.value);
            window.open(
              `https://www.ipay.ua/ua/charger?bill_id=3414&merchantPointId=${meta.merchantPointId}&merchantId=${meta.merchantId}&posterId=${meta.posterId}&invoice=${cost}&desc=${meta.desc}`,
            );
            // displayCurrency.value = cost;
            displayCurrency.classList.remove('error');
          } else if (cost <= 14 || cost === 0) {
            displayCurrency.value = '';
            displayCurrency.placeholder = t('cost');
            displayCurrency.classList.add('error');
          } else {
            displayCurrency.value = '';
            displayCurrency.placeholder = 'вкажіть суму';
            displayCurrency.classList.add('error');
          }
        }}
        >
          {t("button-donation")}
        </button>

        <div className="type-of-cards">
          <img className="header-master_logo" src={logoMaster} alt="" />
          <img className="header-visa_logo" src={logoVisa} alt="" />
          <img className="header-google_logo" src={logoGoogle} alt="" />
          <img className="header-apple_logo" src={logoApple} alt="" />
        </div>
      </div>
    </div>
  );
}
